/* eslint-disable */
let buttons = document.querySelectorAll('.indicator-block__item_toggle');
let items = document.querySelectorAll('.indicator-block__item');
let itemsTarget = document.querySelectorAll('.indicator-block__item_content');

buttons.forEach(function(button) {
  button.addEventListener('click', function(e) {
    e.preventDefault();

    // Check if the clicked item is already active
    let parentItem = this.closest('.indicator-block__item');
    let isActive = parentItem.classList.contains('active');

    // Remove 'active' class from all items and targets
    items.forEach(function(item) {
      item?.classList.remove('active');
    });

    itemsTarget.forEach(function(target) {
      target?.classList.remove('active');
    });

    // Add 'active' class to the clicked item only if it wasn't active
    if (!isActive) {
      parentItem?.classList.add('active');
    }

    adjustBlockPositionIfTooClose(parentItem);
  });
});

document.addEventListener('click', function (e) {
  if (!e.target.closest('.indicator-block__item')) {
    items.forEach(function(item) {
      item?.classList.remove('active');
    });
  }
});

function adjustBlockPositionIfTooClose(parentItem) {
  const block = parentItem.querySelector('.content-wrapper');
  
  const minDistance = 40; 
  const react = block.getBoundingClientRect();
  const windowWidth = window.innerWidth;
  let newPosition = 'calc(50%)';

  const tooFarRight = react.right > windowWidth - minDistance;

  if (tooFarRight) {
    newPosition = `calc(50% - ${Math.abs(react.right - windowWidth + minDistance)}px)`;
  }

  block.style.left = newPosition;
}
